// Farben
@brand-background:              #fff;
@brand-primary:                 #e30613;
@brand-secondary:               #efcccd;
@brand-danger:                  @brand-primary;
@link-color:                    @brand-primary;
@btn-danger-bg:                 @brand-primary;

@font-family-sans-serif:        "Open Sans",arial,sans-serif;
@font-family-serif:             "Source Sans Pro",verdana,arial,sans-serif;
@font-family-monospace:         "Cousine","Courier New",monospace;
@font-family-navbar:		 "Open Sans  Condensed",arial,sans-serif;
@headings-font-family:          @font-family-serif;
@headings-line-height:          1.3;
@headings-font-weight:          600;

// Navbar
// -------------------------

// Basics of a navbar
@navbar-height:                    34px;
@navbar-margin-bottom:             10px;
@navbar-default-color:             #777;
@navbar-default-bg:                @brand-primary;
@navbar-default-border:            #fff;
@navbar-border-radius:             @border-radius-base;
@navbar-padding-horizontal:        (floor(@grid-gutter-width / 2));  // ~15px
@navbar-padding-vertical:          ((@navbar-height - @line-height-computed) / 2                             );
@nav-link-padding:                 10px 10px;

// Navbar links
@navbar-default-link-color:                #fff;
@navbar-default-link-hover-color:          #fff;
@navbar-default-link-hover-bg:             darken(@navbar-default-bg,10%);
@navbar-default-link-active-color:         #fff;
@navbar-default-link-active-bg:            darken(@navbar-default-bg,15%);
@navbar-default-link-disabled-color:       #ccc;
@navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
@navbar-default-brand-color:               @navbar-default-link-color;
@navbar-default-brand-hover-color:         darken(@navbar-default-link-color, 10%);
@navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
@navbar-default-toggle-hover-bg:           lighten(@brand-primary,20%);
@navbar-default-toggle-icon-bar-bg:        #fff;
@navbar-default-toggle-border-color:       #fff;


//Container
@container-large-desktop:      ((1220px + @grid-gutter-width));

// Label+Badges
.badge {
  font-family: @font-family-monospace;
}
.label {
  font-family: @font-family-monospace;
  padding: .1em .3em;
  font-size: 100%;
}

// Allgemein
body {
  background: @brand-background url("/fileadmin/templates/images/bg-schatten.png") repeat-x fixed 0;
}
body.htmlarea-content-body {
  background: #fff;
}

p {
  margin: 0;
}
.lead {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
}


h1,h2,h3,h4,h5,h6 {margin-top:0px;}
h1, .h1 { font-size: 24px; }
h2, .h2 { font-size: 20px; }
h3, .h3 { font-size: 18px; }
h4, .h4 { font-size: 16px; }
h5, .h5 { font-size: 14px; }
h6, .h6 { font-size: 12px; }
h1 { border-bottom:1px @brand-primary solid; }
h2 { border-bottom:1px @brand-primary solid; }

aside h1 { font-size: 20px; }
aside h2 { font-size: 18px; }
aside h3 { font-size: 16px; }
aside h4 { font-size: 14px; }
aside h5 { font-size: 12px; }
aside h6 { font-size: 12px; }

.news-list-item h3 {
	padding:2px;
        color:#000;
	background-color: @brand-secondary;
	border-bottom:1px solid @brand-primary; 
	border-top:1px solid @brand-primary;
}

.navbar {
   font-family: @font-family-navbar;
}

.navbar .nav > li > a {
  text-shadow: 1px 1px 1px black;
}
.navbar .nav > li > a:hover {
  text-shadow: 1px 1px 1px black;
}

//
// Buttons
// --------------------------------------------------

// Common styles
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  .box-shadow(@shadow);

  // Reset the shadow
  &:active,
  &.active {
	.box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
}

// Mixin for generating new styles
.btn-styles(@btn-color: #555;) {
  #gradient > .vertical(@start-color: @btn-color; @end-color: darken(@btn-color,                              10%));
  border-color: darken(@btn-color, 12%);

  &:active,
  &.active {
	background-color: darken(@btn-color, 10%);
	border-color: darken(@btn-color, 12%);
  }
}

// Common styles
.btn {
  // Remove the gradient for the pressed/active state
  &:active,
  &.active {
	background-image: none;
  }
}

// Apply the mixin to the buttons
.btn-default { .btn-styles(@btn-default-bg;); text-shadow: 0 1px 0 #fff; border-color: #ccc; }
.btn-primary { .btn-styles(@btn-primary-bg); }
.btn-success { .btn-styles(@btn-success-bg); }
.btn-warning { .btn-styles(@btn-warning-bg); }
.btn-danger  { .btn-styles(@btn-danger-bg); }
.btn-info    { .btn-styles(@btn-info-bg); }

//Hilfsklassen für Bilder
/* bild links */
img.layout-2 {
  float: left; margin: 0 10px 10px 0;
}
/* bild rechts */
img.layout-3 {
  float: right; margin: 0 0 10px 10px;
}

// NAV LIST
// --------

.nav-list {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
  font-family: @headings-font-family;
  font-size: 16px;
}
.nav-list > li > a,
.nav-list .nav-header {
  margin-left:  -15px;
  margin-right: -15px;
}
.nav-list > li > a {
  padding: 3px 15px;
}
.nav-list > .active > a,
.nav-list > .active > a:hover,
.nav-list > .active > a:focus {
  color: #000;
  background-color: @brand-secondary;
  font-weight: @headings-font-weight;
}
.nav-list [class^="icon-"],
.nav-list [class*=" icon-"] {
  margin-right: 2px;
}

// Nav headers (for dropdowns and lists)
.nav-header {
  display: block;
  padding: 3px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: @line-height-base;
  color: @gray-light;
  text-transform: uppercase;
}
// Space them out when they follow another list item (link)
.nav li + .nav-header {
  margin-top: 9px;
}

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 0px;
  list-style: none;
  background-color: #fff;
  border-radius: 0px;
}

.news-single-imgcaption {
  background-color: #d1d3d4;
}
// RTE Ergänzungen
//
p.graueBox {
  background-color: #d1d3d4;
  padding: 5px;
  margin: 5px;
  border: 1px solid #939598;
}
p.farbigeBox {
  background-color: @brand-secondary;
  padding: 5px;
  margin: 5px;
  border:1px solid @brand-primary;
}
table.normal {
  .table;
}
table.schmal {
  .table;
  .table-condensed;
}
table.streifen {
  .table;
  .table-striped;
}
table.rand {
  .table;
  .table-bordered;
}
table.streifenrand {
  .table;
  .table-bordered;
  .table-striped;
}
table.schmalstreifen {
  .table;
  .table-striped;
  .table-condensed;
}
table.schmalrand {
  .table;
  .table-bordered;
  .table-condensed;
}
table.schmalstreifenrand {
  .table;
  .table-bordered;
  .table-striped;
  .table-condensed;
}
